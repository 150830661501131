import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Browse = () => {
    return (
        <>
            <Helmet>
                <title>Browse Results - Frazzled</title>
                <meta name="description" content="Discover where happiness really is in your career. Take the test and fix your burnout." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col mt-5 mb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="row m-5 w-100">
                                    <div className="col-lg-12 col-12 d-flex flex-column justify-content-center text-center">
                                        <h1 className="d-none d-md-block" style={{ fontSize: '4.5em', marginLeft: '-0.2rem' }}>Salary? Age? Job?</h1>
                                        <h1 className="d-block d-md-none" style={{ fontSize: '3em', marginLeft: '-0.2rem', marginBottom: '2rem' }}>Salary? Age? Job?</h1>
                                        <h4 className="mb-4" style={{ marginTop: '-0.5rem' }}>Find out where career happiness really lies</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row row-cols-2 row-cols-md-4 g-4 mt-5 mb-5">
                                <div className="col mb-4">
                                    <Link to="/search/JobTitle" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/person.svg" alt="Person" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Job Title</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Salary" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/dollarsign.circle.svg" alt="Salary Bracket" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Salary Bracket</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Age" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/birthday.cake.svg" alt="Age" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Age</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Department" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/building.columns.svg" alt="Department" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Department</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Gender" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="https://img.icons8.com/?size=75&id=1665&format=png&color=000000" alt="Gender" className="img-fluid" />
                                            <h5 className="mt-3 mb-3">Gender</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Seniority" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/gauge.with.needle.svg" alt="Seniority" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Seniority</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/WorkingArrangement" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/house.svg" alt="Working Arrangement" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Working Arrangement</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/SexualOrientation" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center rounded" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/heart.svg" alt="Sexual Orientation" className="img-fluid mt-4" />
                                            <h5 className="mt-3 mb-3">Sexual Orientation</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-md-8 text-center m-5">
                            <h3 className="mt-5" style={{ color: '#BFBFBF' }}>Our mission</h3>
                            <h1 className="mt-4" style={{ fontSize: '2.5em', fontStyle: 'italic' }}>"To improve everyone's happiness at work"</h1>
                            <p className="mt-4 mb-5">
                                We aim to eliminate toxic workplaces by collecting and analyzing employee happiness data.
                                We provide anonymized insights to help companies improve, and highlight those excelling in employee happiness.
                                Our goal is to drive continuous improvement across all industries, so everyone can find more joy and fulfillment at work.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Browse;