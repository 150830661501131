import React, { useState, useEffect } from 'react';
import StatisticsCustomProgressBar from './StatisticsCustomProgressBar';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';

const Results = () => {

    const { search } = useLocation();
    const { id } = queryString.parse(search);
    const { parameter } = queryString.parse(search);

    const [categoryScore, setCategoryScore] = useState(50);
    const [frazzledScore, setFrazzledScore] = useState(50);

    const [categoryResults, setCategoryResults] = useState([]);
    const [frazzledResults, setFrazzledResults] = useState([]);

    const [frazzledCompanyResults, setFrazzledCompanyResults] = useState([]);
    const [companyResults, setcompanyResults] = useState([]);





    useEffect(() => {

        fetch('https://frazzled-api.azurewebsites.net/api/v1/statistics?' + parameter + '=' + id)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setCategoryScore(data.categoryTotal)
                setFrazzledScore(data.frazzledTotal)
                setCategoryResults(data.categoryCategories)
                setFrazzledResults(data.frazzledCategories)
                setFrazzledCompanyResults(data.frazzledCompany)
                setcompanyResults(data.categoryCompany)
            })
            .catch((error) => {
                //setError(error);
            });

    }, [parameter, id]);


    const frazzledLookUp = {};
    frazzledResults.forEach(category => {
        frazzledLookUp[category.category] = category.value;
    });

    const frazzledCompanyLookUp = {};
    frazzledCompanyResults.forEach(category => {
        frazzledCompanyLookUp[category.category] = category.value;
    });

    const cellColours = (value, isReverse) => {
        if (value > 70 && isReverse) {
            return 'red';
        } else if (value > 50 && isReverse) {
            return 'orange';
        } else if (isReverse) {
            return 'blue';

        } else if (value > 70 && !isReverse) {
            return 'blue';
        } else if (value > 50 && !isReverse) {
            return 'orange';
        } else if (!isReverse) {
            return 'red';
       
        } else {
            return '';
        }
    }

    return (
        <>
            <Helmet>
                <title>Results - Frazzled</title>
                <meta name="description" content="Analyse your burnout test results" />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid">
            <div className="row" style={{
                    backgroundImage: 'url("/background.svg")',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 mt-5 mb-lg-5 mb-0">
                                    <div class="bg-white shadow-lg h-100 w-100 d-flex align-items-center justify-content-center p-4 rounded">
                                        <div className="text-center">
                                        <h3 className="text-title">Category Average<br />Health Score</h3>
                                        <img src={`/batteries/${categoryScore}.svg`} alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 175 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mt-5 mb-lg-5 mb-5">
                                    <div class="bg-white shadow-lg h-100 w-100 d-flex align-items-center justify-content-center p-4 rounded">
                                        <div className="text-center">
                                            <h3 className="text-title">Frazzled Average<br />Health Score</h3>
                                            <img src={`/batteries/${frazzledScore}.svg`} alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 175 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col mt-5 mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h1>Mental Health Statistics</h1>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="d-flex align-items-center me-4">
                                            <div className="status-box good"></div>
                                            <span className="ms-2">Good</span>
                                        </div>
                                        <div className="d-flex align-items-center me-4">
                                            <div className="status-box average"></div>
                                            <span className="ms-2">Average</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="status-box bad"></div>
                                            <span className="ms-2">Bad</span>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered" style={{ tableLayout: "fixed", width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Category</th>
                                                    <th className="fw-bold text-center">Score</th>
                                                    <th className="text-center">Frazzled Average</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categoryResults.map((item) => (
                                                    <tr>
                                                        <td className="text-center">{item.category}</td>
                                                        <td className={"fw-bold text-center " + cellColours(item.value, item.isReverse)}>{item.value}%</td>
                                                        <td className="fw-bold text-center">{frazzledLookUp[item.category]}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{
                    backgroundImage: 'url("/background.svg")',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center" // Center content vertically
                }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="row justify-content-center mt-5 mb-4">

                                        {companyResults.map((item, index) => (
                                            <div key={index} className="col-lg-6 col-md-12 col-12 mb-4">
                                                <div className="bg-white p-xl-5 p-lg-3 p-5 shadow-lg text-center rounded">
                                                    <StatisticsCustomProgressBar
                                                        title={item.category}
                                                        yourRating={item.value}
                                                        averageRating={frazzledCompanyLookUp[item.category]}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Results;
