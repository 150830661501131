import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import 'bootstrap-icons/font/bootstrap-icons.css';
import SearchableTextField from './SearchableTextField';
import { Helmet } from 'react-helmet-async';

// Create class...

const Test = () => {

    const pageSize = 5;

    const [page, setPage] = useState(1);
    const [showForm, setShowForm] = useState(false);




    const navigate = useNavigate();

    const [error, setError] = useState(null);

    const [genders, setGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState('');

    const [ages, setAges] = useState([]);
    const [selectedAge, setSelectedAge] = useState('');

    const [salaries, setSalaries] = useState([]);
    const [selectedSalary, setSelectedSalary] = useState('');

    const [deparments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');

    const [workingArrangements, setWorkingArrangements] = useState([]);
    const [selectedWorkingArrangement, setSelectedWorkingArrangement] = useState('');

    const [sexualOrientations, setSexualOrientations] = useState([]);
    const [selectedSexualOrientation, setSelectedSexualOrientation] = useState('');

    const [seniorities, setSeniorities] = useState([]);
    const [selectedSeniority, setSelectedSeniority] = useState('');

    const [ethnicities, setEthnicities] = useState([]);
    const [selectedEthnicity, setSelectedEthnicity] = useState('');

    const [questions, setQuestions] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});

    const [yearsInPost, setYearsInPost] = useState(null);
    const [monthsInPost, setMonthsInPost] = useState(null);

    const [company, setCompany] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const handleYearsInPostChange = (e) => {
        setYearsInPost(e.target.value);
    };
    const handleMonthsInPostChange = (e) => {
        setMonthsInPost(e.target.value);
    };
    const handleEmailAddressChange = (e) => {
        setEmailAddress(e.target.value);
    };

    const [mentalHealthProgress, setMentalHealthProgress] = useState(0);
    const [companyProgress, setCompanyProgress] = useState(0);
    const [infoProgress, setInfoProgress] = useState(0);

    const [totalCompanyQuestions, setTotalCompanyQuestions] = useState(0);
    const [totalMentalHealthQuestions, setTotalMentalHealthQuestions] = useState(0);
    // End Info Questions
    const totalFields = 13;

    useEffect(() => {
        const completedFields = [
            company,
            jobTitle,
            selectedDepartment,
            selectedSalary,
            selectedSeniority,
            selectedWorkingArrangement,
            yearsInPost,
            monthsInPost,
            selectedAge,
            selectedGender,
            selectedSexualOrientation,
            selectedEthnicity,
            emailAddress,
        ].filter(Boolean).length;

        // Calculate progress percentage
        const progressValue = (completedFields / totalFields) * 100;
        setInfoProgress(progressValue);
    }, [
        company,
        jobTitle,
        selectedDepartment,
        selectedSalary,
        selectedSeniority,
        selectedWorkingArrangement,
        yearsInPost,
        monthsInPost,
        selectedAge,
        selectedGender,
        selectedSexualOrientation,
        selectedEthnicity,
        emailAddress,
    ]);


    // We need to check all the questions have been answered before submitting


    const fetchQuestions = async () => {

        fetch('https://frazzled-api.azurewebsites.net/api/v1/questions')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setQuestions(data.map(i => ({ id: i.questionId, description: i.displayText, questionSetId: i.questionSetId })));
                setTotalMentalHealthQuestions(data.filter(question => question.questionSetId === 1).length);
                setTotalCompanyQuestions(data.filter(question => question.questionSetId === 2).length);

            })
            .catch((error) => {
                setError(error);

            });
    };


    const fetchData = async (endpoint, setMethod) => {
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok' + endpoint);
                }
                return response.json();
            })
            .then((data) => {

                setMethod(data);

            })
            .catch((error) => {
                setError(error);

            });
    }


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    const updateProgress = () => {
        let completedMentalHealth = 0;
        let completedCompany = 0;

        // Count the completed questions
        questions.forEach(question => {
            if (selectedAnswers.hasOwnProperty(question.id)) {
                if (question.questionSetId === 1) {
                    completedMentalHealth++;
                } else {
                    completedCompany++;
                }
            }
        });

        // Calculate the progress percentages
        const mentalHealthProgress = completedMentalHealth / totalMentalHealthQuestions;
        const companyProgress = completedCompany / totalCompanyQuestions;

        // Update the state for mental health and company progress
        setMentalHealthProgress(mentalHealthProgress);

        // Handle company progress, setting it to 1 if it exceeds 0.96
        if (companyProgress >= 0.96) {
            setCompanyProgress(1);  // Cap it at 1
        } else {
            setCompanyProgress(companyProgress);  // Set the calculated value
        }

        // Update info progress (assuming it's calculated separately)
        setInfoProgress(infoProgress);
    };

    const changePage = (forward) => {

        let numberOfQuesstion = questions.length;

        if (forward) {

            const currentQuestions = questions.slice(pageSize * (page - 1), pageSize * page);

            const complete = currentQuestions.every((question) =>
                selectedAnswers.hasOwnProperty(question.id)
            );

            if (complete === false) return; // Need to scroll to the right place

            if (page < numberOfQuesstion / pageSize) {
                setPage(page + 1);
                scrollToTop();
            }
            else {
                setShowForm(true);
            }
        }
        else {
            if (page > 1) {
                setPage(page - 1);
            }
        }
    };

    const handleAnswerSelect = (questionId, answerId) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [questionId]: answerId
        });
        updateProgress();
        window.scrollBy(0, 180)


    };


    const Answer = ({ isSelected, onSelect, value }) => {
        return (
            <label class="custom-radio">
                <input
                    type="radio"

                    checked={isSelected}
                    onChange={onSelect}
                />
                <span class={"radio-mark " + (value === 3 ? "gray" : value > 3 ? "blue" : "red") + " " + (value === 3 ? "xs" : value === 2 || value === 4 ? "sm" : value === 1 || value === 5 ? "md" : "lg")}></span>
            </label>
        );
    };


    const Question = ({ question, selectedAnswer, onAnswerSelect }) => {
        return (
            <div className="row" style={{ opacity: (selectedAnswer != null) ? '0.3' : 1 }}>
                <div className="col border-bottom pb-3 mt-3 mb-4">
                    <div className="row">
                        <div className="col text-center">
                            <p className="fs-4 mb-4" style={{ fontWeight: 500 }}>{question.description}</p>
                        </div>
                    </div>
                    <div className="row mb-4 align-items-center">
                        {/* Disagree element for large screens */}
                        <div className="col-12 col-md-2 d-none d-md-block" style={{ color: '#DB4E69', fontWeight: 500 }}>Disagree</div>

                        {/* Array of answers */}
                        <div className="col-12 col-md-8 d-flex flex-wrap justify-content-center">
                            {Array.from({ length: 7 }, (v, index) => (
                                <div className="col ps-0 pe-0" key={index + 1}>
                                    <Answer
                                        value={index}
                                        isSelected={selectedAnswer === index + 1}
                                        onSelect={() => onAnswerSelect(question.id, index + 1)}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Agree element for large screens */}
                        <div className="col-12 col-md-2 d-none d-md-block text-end" style={{ color: '#25CED1', fontWeight: 500 }}>Agree</div>
                    </div>

                    {/* Disagree element for smaller screens */}
                    <div className="row align-items-center d-md-none">
                        <div className="col-6 mt-2" style={{ color: '#DB4E69', fontWeight: 500 }}>Disagree</div>
                        <div className="col-6 mt-2 text-end" style={{ color: '#25CED1', fontWeight: 500 }}>Agree</div>
                    </div>
                </div>
            </div>
        );
    };

    const QuestionList = ({ questions, selectedAnswers, onAnswerSelect }) => {
        return (
            <div className="question-list">
                {questions.map((question, index) => (
                    <Question
                        key={question.id}
                        question={question}
                        selectedAnswer={selectedAnswers[question.id]}
                        onAnswerSelect={onAnswerSelect}
                    />
                ))}
            </div>
        );
    };

    const SubmitTest = async () => {

        console.log(company)
        console.log(jobTitle)

        fetch('https://frazzled-api.azurewebsites.net/api/v1/responses',
            //fetch('https://localhost:7053/api/v1/responses',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    EmailAddress: emailAddress,
                    VerificationCode: '',
                    CompanyName: company,
                    JobTitle: jobTitle,
                    AgeId: parseInt(selectedAge),
                    SalaryId: parseInt(selectedSalary),
                    DepartmentId: parseInt(selectedDepartment),
                    EthnicityId: parseInt(selectedEthnicity),
                    GenderId: parseInt(selectedGender),
                    SeniorityId: parseInt(selectedSeniority),
                    SexualOrientationId: parseInt(selectedSexualOrientation),
                    WorkingArrangementId: parseInt(selectedWorkingArrangement),
                    YearsInPost: parseInt(yearsInPost),
                    MonthsInPost: parseInt(monthsInPost),

                    Answers: Object.keys(selectedAnswers).map(key => ({
                        questionId: parseInt(key),
                        value: selectedAnswers[key]
                    }))

                    //QuestionId, Value

                })
            })

            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                navigate('/results?responseId=' + data.responseId + '&key=' + data.key);
            })
            .catch((error) => {
                setError(error);
            });
    }

    const DefaultSelectListItem = () => { return (<option value="" disabled>Please select...</option>); };

    useEffect(() => {

        fetchQuestions();
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/genders', setGenders)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/ages', setAges)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/salaries', setSalaries)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/departments', setDepartments)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/sexualorientations', setSexualOrientations)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/ethnicities', setEthnicities)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/workingarrangements', setWorkingArrangements)
        fetchData('https://frazzled-api.azurewebsites.net/api/v1/seniorities', setSeniorities)
    }, []);

    /*
    if (loading) {
        return <div>Loading...</div>;
    }*/

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <Helmet>
                <title>Burnout Test - Frazzled</title>
                <meta name="description" content="Discover where happiness really is in your career. Take the test and fix your burnout." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div>
                <div class="container-fluid">
                    <div class="row" style={{ backgroundImage: 'url( "background.svg" )', backgroundSize: "cover" }}>
                        <div ckass="col">
                            <div className="container">
                                <div class="row mt-5 justify-content-center">
                                    <div class="col-sm-12 col-md-4 mb-3 d-md-none">
                                        <div className="bg-white pt-4 pb-4 ps-5 pe-5 shadow-lg text-center rounded">
                                            <h3 className="justify-content-center mb-4">Progress</h3>
                                            <p>Check in on your mental health, rate your workplace, and get personalised job recommendations powered by AI.</p>
                                            <div className="progress" style={{ height: '10px' }}>
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: (mentalHealthProgress / 3 + companyProgress / 3 + infoProgress / 3) * 100 + '%', backgroundColor: '#25CED1' }}
                                                    aria-valuenow={mentalHealthProgress * 100}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                            <h6 className="mt-4" style={{ color: '#BFBFBF', fontWeight: '500' }}>
                                                <i className="bi bi-clock me-2"></i>
                                                {mentalHealthProgress / 3 + companyProgress / 3 + infoProgress / 3 === 1 ? 'Done' : `${Math.round((1 - (mentalHealthProgress / 3 + companyProgress / 3 + infoProgress / 3)) * 5)} minutes left`}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 mb-3 d-none d-md-block">
                                        <div className="bg-white p-5 shadow-lg text-center rounded">
                                            <h3 className="justify-content-center">Mental Health</h3>
                                            <p>Score your mental health to find out how you compare to other people.</p>
                                            <div className="progress" style={{ height: '10px' }}>
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: mentalHealthProgress * 100 + '%', backgroundColor: '#25CED1' }}
                                                    aria-valuenow={mentalHealthProgress * 100}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                            <h6 className="mt-3" style={{ color: '#BFBFBF', fontWeight: '500' }}>
                                                <i className="bi bi-clock me-2"></i>
                                                {mentalHealthProgress === 1 ? 'Done' : `${Math.round((1 - mentalHealthProgress) * 2)} minutes left`}
                                            </h6>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-4 mb-3 d-none d-md-block">
                                        <div className="bg-white p-5 shadow-lg text-center rounded">
                                            <h3>Company Review</h3>
                                            <p>Give leaders the data they need to improve career happiness.</p>
                                            <div className="progress" style={{ height: '10px' }}>
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: companyProgress * 100 + '%', backgroundColor: '#25CED1' }}
                                                    aria-valuenow={companyProgress * 100}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                            <h6 className="mt-3" style={{ color: '#BFBFBF', fontWeight: '500' }}>
                                                <i className="bi bi-clock me-2"></i>
                                                {companyProgress === 1 ? 'Done' : `${Math.round((1 - companyProgress) * 2)} minutes left`}
                                            </h6>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-4 mb-3 d-none d-md-block">
                                        <div className="bg-white p-5 shadow-lg text-center rounded">
                                            <h3>About you</h3>
                                            <p>Get AI powered recommendations to help you make career decisions.</p>
                                            <div className="progress" style={{ height: '10px' }}>
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: infoProgress + '%', backgroundColor: '#25CED1' }}
                                                    aria-valuenow={infoProgress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                            <h6 className="mt-3" style={{ color: '#BFBFBF', fontWeight: '500' }}>
                                                <i className="bi bi-clock me-2"></i>
                                                {infoProgress === 100 ? 'Done' : '1 minute left'}
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-sm-12 col-md-8 col-lg-4 col-xl-4 mt-4">
                                        <div className="d-flex align-items-center bg-white shadow-lg mb-5 pt-3 pb-3 ps-3 pe-3 rounded">
                                            <div>
                                                <p className="mb-0 text-center" style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '0.2px' }}>
                                                    We anonymise data before we publish it to help people and drive change, your identity will never be revealed.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">

                    <div class="row justify-content-center mt-4">

                        <div class="col-sm-11 col-md-10 col-lg-6 col-xl-7 p-3 r">
                            {
                                showForm ? (
                                    <div>
                                        <div className="row">
                                            <div className="col border-bottom pb-3 mt-3 mb-4">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <p className="fs-4 mb-0" style={{ fontWeight: 500 }}>Between these, which do you value most?</p>
                                                        <p className="fs-5 mb-4" style={{ fontWeight: 300 }}>Use your gut feeling, you can't go back.</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-4 align-items-center mt-4">
                                                    {/* Passion Label with Centered Checkbox */}
                                                    <div className="col-12 col-md-4 d-flex flex-column align-items-center fs-5" style={{ fontWeight: 800 }}>
                                                        Passion
                                                        <div className="form-check mt-2">
                                                            <input type="checkbox" className="form-check-input" id="passionCheckbox" />
                                                        </div>
                                                    </div>

                                                    {/* VS Section */}
                                                    <div className="col-12 col-md-4 d-flex justify-content-center">
                                                        <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                                                            <p className="m-0 text-center">vs</p>
                                                        </div>
                                                    </div>

                                                    {/* Balance Label with Centered Checkbox */}
                                                    <div className="col-12 col-md-4 d-flex flex-column align-items-center fs-5" style={{ fontWeight: 800 }}>
                                                        Balance
                                                        <div className="form-check mt-2">
                                                            <input type="checkbox" className="form-check-input" id="balanceCheckbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom pb-3 mb-3 mt-4">
                                            <div class="col-sm-12 col-md-4 pe-5">
                                                <h5 className="fw-bold">About your job</h5>
                                                <p>We collect these to compare your scores to other peoples. We will not publish any of these attributes if they can be tracked back to an individual.</p>
                                            </div>

                                            <div class="col-sm-12 col-md-8">
                                                <label class="form-label text-muted">Company <span class="text-danger">*</span></label>
                                                <SearchableTextField apiEndpoint="https://frazzled-api.azurewebsites.net/api/v1/companies" searchField="name" displayTextProperty="name" idProperty="companyId" setValue={setCompany} />
                                                <label class="form-label text-muted">Job Title <span class="text-danger">*</span></label>
                                                <SearchableTextField apiEndpoint="https://frazzled-api.azurewebsites.net/api/v1/jobtitles" searchField="jobtitle" displayTextProperty="title" idProperty="jobTitleId" setValue={setJobTitle} />
                                                <label class="form-label text-muted">Department <span class="text-danger">*</span></label>
                                                <select value={selectedDepartment} onChange={(event) => setSelectedDepartment(event.target.value)} class="form-control mb-3 ">
                                                    <DefaultSelectListItem />
                                                    {deparments.map((item) => (
                                                        <option key={item.departmentId} value={item.departmentId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label class="form-label text-muted">Salary Bracket <span class="text-danger">*</span></label>
                                                <select value={selectedSalary} onChange={(event) => setSelectedSalary(event.target.value)} class="form-control mb-3 ">
                                                    <DefaultSelectListItem />
                                                    {salaries.map((item) => (
                                                        <option key={item.salaryId} value={item.salaryId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label class="form-label text-muted">Seniority <span class="text-danger">*</span></label>
                                                <select value={selectedSeniority} onChange={(event) => setSelectedSeniority(event.target.value)} class="form-control mb-3">
                                                    <DefaultSelectListItem />
                                                    {seniorities.map((item) => (
                                                        <option key={item.seniorityId} value={item.seniorityId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label class="form-label text-muted">Working Arrangement</label>
                                                <select value={selectedWorkingArrangement} onChange={(event) => setSelectedWorkingArrangement(event.target.value)} class="form-control mb-3">
                                                    <DefaultSelectListItem />
                                                    {workingArrangements.map((item) => (
                                                        <option key={item.workingArrangementId} value={item.workingArrangementId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>

                                                <label class="form-label">Years in Service</label>
                                                <div class="input-group mb-5">
                                                    <input type="number" class="form-control" value={yearsInPost} onChange={handleYearsInPostChange} placeholder="Years" min="0" max="70"></input>
                                                    <input type="number" class="form-control" value={monthsInPost} onChange={handleMonthsInPostChange} placeholder="Months" min="0" max="11"  ></input>

                                                </div>



                                            </div>
                                        </div>

                                        <div class="row border-bottom pb-3 mt-5 mb-3">
                                            <div class="col-sm-12 col-md-4 pe-5">
                                                <h5 className="fw-bold">About you</h5>
                                                <p>We collect these for the purpose of showing how other people similar to you feel and where they are happy. It can also help bring hard facts to the fight for equality.</p>
                                            </div>

                                            <div class="col-sm-12 col-md-8">
                                                <label class="form-label text-muted">Age Range</label>
                                                <select value={selectedAge} onChange={(event) => setSelectedAge(event.target.value)} class="form-control mb-3">
                                                    <DefaultSelectListItem />
                                                    {ages.map((item) => (
                                                        <option key={item.ageId} value={item.ageId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>

                                                <label class="form-label text-muted">Gender</label>
                                                <select value={selectedGender} onChange={(event) => setSelectedGender(event.target.value)} class="form-control mb-3">
                                                    <DefaultSelectListItem />
                                                    {genders.map((item) => (
                                                        <option key={item.genderId} value={item.genderId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>

                                                <label class="form-label text-muted">Sexual Orientation</label>
                                                <select value={selectedSexualOrientation} onChange={(event) => setSelectedSexualOrientation(event.target.value)} class="form-control mb-3">
                                                    <DefaultSelectListItem />
                                                    {sexualOrientations.map((item) => (
                                                        <option key={item.sexualOrientationId} value={item.sexualOrientationId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>

                                                <label class="form-label text-muted">Ethnicity</label>
                                                <select value={selectedEthnicity} onChange={(event) => setSelectedEthnicity(event.target.value)} class="form-control mb-5">
                                                    <DefaultSelectListItem />
                                                    {ethnicities.map((item) => (
                                                        <option key={item.ethnicityId} value={item.ethnicityId}>
                                                            {item.displayText}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>

                                        </div>

                                        <div class="row pb-3 mt-5 mb-3">
                                            <div class="col-sm-12 col-md-4 pe-5 mb-3">
                                                <h5 className="fw-bold">Verification</h5>
                                                <p>We won't send you an email. This is how we ensure each response is unique.</p>
                                            </div>

                                            <div class="col-sm-12 col-md-8">
                                                <label class="form-label text-muted">Personal Email Address</label>
                                                <div class="input-group mb-3">
                                                    <input type="email" class="form-control"
                                                        value={emailAddress}
                                                        onChange={handleEmailAddressChange}
                                                    ></input>
                                                </div>
                                            </div>

                                        </div>
                                        {/*  OLD VERIFICATION CODE                              
                                    <div class="row pb-3 mt-5 mb-3">
                                        <div class="col-sm-12 col-md-4 pe-5 mb-3">
                                            <h5 className="fw-bold">Verification</h5>
                                            <p>You aren’t signing up for anything, no marketing emails. This is just so we know you are a genuine human. We will send you a code to enter here.</p>
                                        </div>

                                        <div class="col-sm-12 col-md-8">
                                            <label class="form-label text-muted">Personal Email Address</label>
                                            <div class="input-group mb-3">
                                                <input type="email" class="form-control"
                                                    value={emailAddress}
                                                    onChange={handleEmailAddressChange}
                                                ></input>
                                                <span class="input-group-text fw-bold text-white" style={{ backgroundColor: '#EA526F', color: '#FFFFFF' }}>Get Code</span>
                                            </div>

                                            <label class="form-label text-muted">Enter Code from Email</label>
                                            <div class="input-group mb-3">
                                                <input type="email" class="form-control"></input>
                                                <span class="input-group-text fw-bold text-white ps-4 pe-4" style={{ backgroundColor: '#EA526F', color: '#FFFFFF' }}>Verify</span>

                                            </div>

                                        </div>

                                    </div>
                                    */}
                                        <div className="row mb-5">
                                            <div className="col-12 d-flex justify-content-center">
                                                <button onClick={SubmitTest} className="btn fw-bold" style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: 125 }}>See Results</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <QuestionList
                                            questions={questions.slice(pageSize * (page - 1), pageSize * page)}
                                            selectedAnswers={selectedAnswers}
                                            onAnswerSelect={handleAnswerSelect}
                                        />

                                        <div className="row mt-5 mb-5">
                                            <div className="col-12 d-flex justify-content-center mb-5">
                                                {page > 1 ? (
                                                    <button onClick={() => changePage(false)} class="btn me-3 fw-bold" style={{ backgroundColor: '#F08FA2', color: '#FFFFFF', width: 105 }}>Previous</button>
                                                ) : ""}

                                                <button onClick={() => changePage(true)} class="btn fw-bold" style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: 80 }}>Next</button>
                                            </div>
                                        </div>

                                    </div>
                                )}
                        </div>
                    </div>

                </div>

            </div >
        </>
    );
};




export default Test;